import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StaticImage} from 'gatsby-plugin-image';

import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import {ContrastContext} from '../../helpers/context';
import {ContactSalesButton, PrimaryButton} from '../../components/common/Clickables';
import SEO from '../../components/common/SEO';
import ProductHero from '../../components/common/ProductHero';
import ProductPageContainer from '../../components/common/ProductPageContainer';
import {Hero, H1, H4, H5, P, PMono, P2Mono, H6Mono, BlockQuote} from '../../components/common/Typography';
import MatrixTopImage from '../../images/product/reconciliation/matrix-top.png';
import MatrixBottomImage from '../../images/product/reconciliation/matrix-bottom.png';
import OutstandingTransactionsImage from '../../images/product/reconciliation/outstanding-transactions.no-inline.svg';
import TestimonyBackground from '../../images/product/reconciliation/testimony-background.png';
import TestimonyImage from '../../images/product/reconciliation/testimony-image.jpg';
import TransactionsMatch from '../../images/product/reconciliation/transactions-match.svg';
import {breakpoints, colors, ContentContainer, fonts, fontWeights} from '../../styles/theme';
import AnimatedBackground from '../../components/common/AnimatedBackground';


/*
 * Constants
 */
/* eslint-disable-next-line i18next/no-literal-string */
const videoUrl = null;


/*
 * Private Elements
 */
const ManageTransactionsContainer = styled.div`
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;
  
    background: url(${MatrixTopImage}) no-repeat top center/contain,
                white url(${MatrixBottomImage}) no-repeat bottom center/contain;

    ${ContentContainer} {
        .content {
            margin-bottom: 50px;

            ${Hero} {
                margin-bottom: 25px;
            }

            ${H5} {
                font-weight: ${fontWeights.regular};
            }
        }

        .transactions-match {
            display: flex;
            justify-content: center;

            svg {
                width: 130%;
                height: auto;
                flex-shrink: 0;
                user-select: none;
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            .transactions-match {
                display: unset;
                padding-right: 30px;

                svg {
                    width: 100%;
                }
            }
        }
    }

    @media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
        padding-top: 150px;
        padding-bottom: 120px;
    }

    @media (min-width: ${breakpoints.lg}) {
        padding-top: 200px;
        padding-bottom: 180px;
    }
`;

const TestimonyContainer = styled.div`
    clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 calc(100% - 100px));
    color: ${colors.white};
    margin-top: -100px;
    padding-top: 150px;
    padding-bottom: 150px;
    background: ${colors.black} url(${TestimonyBackground}) no-repeat scroll center top;
    background-size: contain;
    position: relative;

    ${ContentContainer} {
        margin-bottom: 30%;

        .blockquote {
            ${Hero} {
                vertical-align: top;
                color: ${colors.darkPurple};
                font-family: ${fonts.monospace};
            }

            ${H4} {
                margin-bottom: 25px;
            }

            ${PMono} {
                color: ${colors.darkPurple};
                font-weight: ${fontWeights.bold};
            }
        }
    }

    .testimony-image {
        position: absolute;
        height: 50%;
        width: 100%;
        bottom: 0;
        background: ${colors.transparent} url(${TestimonyImage}) no-repeat scroll center bottom;
        background-size: cover;
        opacity: 50%;
        filter: grayscale(100%);
        mask-image: linear-gradient(to bottom, ${colors.transparent}, ${colors.black});
    }
  
    @media (min-width: ${breakpoints.md}) {
        clip-path: polygon(0 0, 100% 250px, 100% 100%, 0 calc(100% - 250px));
        margin-top: -250px;
        padding-top: 250px;
        padding-bottom: 300px;
        background: ${colors.black} url(${TestimonyBackground}) no-repeat scroll center center;
        background-size: cover;

        ${ContentContainer} {
            margin-bottom: 0;
        }

        .testimony-image {
            height: 100%;
            width: 50%;
            right: 0;
            top: 0;
            mask-image: linear-gradient(to right, ${colors.transparent}, ${colors.black});
        }
    }
`;

const RecoverOutstandingFundsContainer = styled.div`
    padding-top: 50px;
    text-align: center;

    ${ContentContainer} {
        position: relative;
        z-index: 1;

        ${H1} {
            margin-bottom: 30px;
        }

        ${H5} {
            font-weight: ${fontWeights.regular};
            margin-left: auto;
            margin-right: auto;
        }
    }

    .outstanding-transactions {
        height: 270px;
        background: url(${OutstandingTransactionsImage}) center/cover no-repeat;
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            ${H5} {
                max-width: 65%;
            }
        }

        .outstanding-transactions {
            height: 510px;
            margin-top: -100px;
        }
    }
  
    @media (min-width: ${breakpoints.lg}) {
        ${ContentContainer} {
            ${H5} {
                max-width: 650px;
            }
        }
    }
`;

const HaveYouCoveredContainer = styled.div`
    background-color: ${colors.black};
    color: ${colors.white};
    text-align: center;
    padding-top: 50px;
    padding-bottom: 90px;

    ${ContentContainer} {
        ${Hero} {
            margin-bottom: 40px;
        }

        ${PrimaryButton} {
            margin-top: 50px;
        }

        .cards {
            display: grid;

            .card {
                background-color: ${colors.transparent};
                border: none;
                border-radius: 0;
                box-shadow: inset 0 6px 4px -1px ${colors.black};
            }

            .padding {
                background-color: ${colors.black};
                min-height: 20px;
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        padding-top: 100px;

        ${ContentContainer} {
            padding-left: 65px;
            padding-right: 65px;

            ${Hero} {
                margin-bottom: 80px;
            }

            .cards {
                grid-template-columns: 31% 1fr 31% 1fr 31%;
            } 
        }
    }
`;

const StyledHaveYouCoveredCard = styled.div`
    height: 100%;
    padding: 25px;
    color: ${colors.white};
    text-align: left;

    .header {
        margin-bottom: 1.2rem;

        .image {
            width: 70px;
            margin-bottom: 2rem;
        }

        ${H6Mono} {
            font-weight: ${fontWeights.bold};
            font-size: 16px;
        }
    }

    .content {
        ${P} {
            font-size: 16px;
            line-height: 24px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .header {
            ${H6Mono} {
                font-size: 18px;
            }
        }

        .content {
            ${P} {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
`;

const HaveYouCoveredCard = ({image, title, children}) => (
    <StyledHaveYouCoveredCard>
        <div className="header">
            <div className="image">{image}</div>
            <H6Mono>{title}</H6Mono>
        </div>
        <div className="content">{children}</div>
    </StyledHaveYouCoveredCard>
);

HaveYouCoveredCard.propTypes = {
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};


/*
 * Public Elements
 */
const ReconciliationPage = () => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();

    const section = t('Reconciliation');
    // eslint-disable-next-line i18next/no-literal-string
    const sectionStr = 'Reconciliation';
    const hero = t('Automatically match your transactions.');
    const description = t('Identify mismatches, fees, and reconciliation errors across multiple payment providers, bank'
        + ' accounts, and platforms.');
    const image = (
        <StaticImage src="../../images/product/reconciliation/logo.png" placeholder="none" loading="eager" alt="" />
    );
    const mobileImage = (
        <StaticImage
            src="../../images/product/reconciliation/logo-mobile.png"
            placeholder="none"
            loading="eager"
            alt=""
        />
    );

    return (
        <>
            <SEO
                title={section}
                ogTitle={t('Automate Payment Reconciliation - Switch')}
                description={t('Match and reconcile transactions automatically. Identify fees and mismatches across '
                    + 'multiple payment providers, bank accounts, and platforms.')}
                image="/website/metatags/reconciliation.png"
            />

            <ProductHero
                section={section}
                sectionStr={sectionStr}
                hero={hero}
                description={description}
                image={image}
                mobileImage={mobileImage}
                videoUrl={videoUrl}
            />

            <ProductPageContainer>
                <ManageTransactionsContainer className="content">
                    <ContentContainer>
                        <div className="row no-gutters content">
                            <div className="col-12 col-md-5 offset-md-1 col-lg-4 offset-lg-2">
                                <Hero><Trans>Make sure your money is where it should be.</Trans></Hero>
                            </div>
                            <div className="col-12 col-md-4 offset-md-1">
                                <H5>
                                    <Trans>
                                        Managing millions of transactions makes it harder to control unit costs and spot
                                        fee inconsistencies. We track commission variations across providers, markets,
                                        and payment methods.
                                    </Trans>
                                </H5>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6 transactions-match">
                                <TransactionsMatch />
                            </div>
                        </div>
                    </ContentContainer>
                </ManageTransactionsContainer>

                <div className="product-shadow-clip">
                    <TestimonyContainer>
                        <ContentContainer>
                            <div className="row no-gutters">
                                <div className="col-12 col-md-5 offset-md-1 col-lg-4 offset-lg-2 quote">
                                    <BlockQuote>
                                        <H4>
                                            <Trans>
                                                When there are failures in communication between systems, these need to
                                                be analyzed promptly. This is where Switch Reconciliation has been a
                                                great help to avoid constraints with our clients.
                                            </Trans>
                                        </H4>

                                        <PMono><Trans>Vanda Madeira</Trans></PMono>
                                        <P2Mono><Trans>Senior Project Manager at Hospital da Luz</Trans></P2Mono>
                                    </BlockQuote>
                                </div>
                            </div>
                        </ContentContainer>

                        <div className="testimony-image" />
                    </TestimonyContainer>
                </div>

                <RecoverOutstandingFundsContainer>
                    <ContentContainer>
                        <H1><Trans>Recover monthly outstanding funds.</Trans></H1>

                        <H5>
                            <Trans>
                                It&apos;s important to keep track of settlement schedules with a direct impact on
                                working capital. Our platform processes transaction statements from multiple sources and
                                provides settlement events for data filtering.
                            </Trans>
                        </H5>
                    </ContentContainer>

                    <div className="outstanding-transactions" />
                </RecoverOutstandingFundsContainer>

                <HaveYouCoveredContainer>
                    <ContentContainer>
                        <Hero><Trans>We&apos;ve got you covered.</Trans></Hero>

                        <AnimatedBackground>
                            <div className="row no-gutters cards">
                                <div className="card">
                                    <HaveYouCoveredCard
                                        image={(
                                            <StaticImage
                                                src="../../images/product/reconciliation/channels.png"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={70}
                                                alt=""
                                            />
                                        )}
                                        title={t('Keep Your Payment Channels')}
                                    >
                                        <P>
                                            <Trans>
                                                Our modular architecture supports lifecycle events from external
                                                sources, so businesses can inject processing events into our platform.
                                            </Trans>
                                        </P>
                                    </HaveYouCoveredCard>
                                </div>
                                <div className="padding" />
                                <div className="card">
                                    <HaveYouCoveredCard
                                        image={(
                                            <StaticImage
                                                src="../../images/product/reconciliation/support.png"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={70}
                                                alt=""
                                            />
                                        )}
                                        title={t('Get Account Support')}
                                    >
                                        <P>
                                            <Trans>
                                                We offer a dedicated account manager and 24/7 one-to-one support to fix
                                                any problems promptly.
                                            </Trans>
                                        </P>
                                    </HaveYouCoveredCard>
                                </div>
                                <div className="padding" />
                                <div className="card">
                                    <HaveYouCoveredCard
                                        image={(
                                            <StaticImage
                                                src="../../images/product/reconciliation/processing-fees.png"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={70}
                                                alt=""
                                            />
                                        )}
                                        title={t('Control Processing Fees')}
                                    >
                                        <P>
                                            <Trans>
                                                With our centralized Dashboard you&apos;ll be able to control all
                                                processing fees across different providers.
                                            </Trans>
                                        </P>
                                    </HaveYouCoveredCard>
                                </div>
                            </div>
                        </AnimatedBackground>

                        <ContactSalesButton />
                    </ContentContainer>
                </HaveYouCoveredContainer>
            </ProductPageContainer>
        </>
    );
};


/*
 * Exports
 */
export default ReconciliationPage;
